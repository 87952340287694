<template>
  <a-spin tip="加载中..." :spinning="isSpinning">
    <a-form :form="form">
      <a-form-item
        label="机构名称"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-input
          placeholder="请输入机构名称"
          v-decorator="[
            'name',
            {
              initialValue: record.name,
              rules: [{ required: true, message: '请输入机构名称' }],
            },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="机构性质"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-select
          placeholder="请选择机构性质"
          v-decorator="[
            'type',
            {
              initialValue: record.type || 'INSTITUTION',
              rules: [{ required: true }],
            },
          ]"
          style="width: 160px"
        >
          <a-select-option v-for="i in typeList" :value="i.type" :key="i.type">
            {{ i.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { addOrg } from "@/api/organization";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
      isSpinning: false,
      typeList: [
        {
          type: "INSTITUTION",
          text: "机构",
        },
        {
          type: "PRODUCT",
          text: "项目",
        },
        {
          type: "OTHER",
          text: "其他",
        },
      ],
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.isSpinning = true;
            addOrg(values)
              .then((res) => {
                this.$message.success("添加成功");
                resolve(res);
              })
              .finally(() => {
                this.isSpinning = false;
              });
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>
