<template>
  <div class="organization-list">
    <div class="action-area">
      <a-button
        type="primary"
        icon="plus"
        @click="handleEdit()"
        v-if="hasAction('add-or-delete-organization')"
        >新增机构</a-button
      >
      <a-input-search
        v-model="params.inputStr"
        class="input-bar"
        placeholder="请输入机构名称"
        @search="onSearch"
      />
    </div>
    <a-table
      :columns="table.columns"
      :data-source="params.organizationList"
      :loading="table.loading"
      rowKey="id"
      @change="onTableChange"
      :pagination="pagination"
    >
      <template slot="dataManage" slot-scope="text, record">
        <div>
          <a-popconfirm
            title="是否确认删除该机构？"
            ok-text="是"
            cancel-text="否"
            :visible="record.delShow"
            @confirm="confirmDelete(record)"
            @cancel="record.delShow = false"
            @visibleChange="record.delShow = false"
          >
            <a @click="onDelClick(record)">删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import OrgForm from "./components/OrgForm";
import { searchOrg, deleteOrg, checkOrgEnableDel } from "@/api/organization";
import storage from "store";

export default {
  name: "OrganizationList",
  data() {
    return {
      params: {
        inputStr: "",
        organizationList: [],
      },
      table: {
        loading: false,
        columns: [
          {
            title: "机构名称",
            dataIndex: "name",
            customRender: (text, row) => {
              return (
                <a
                  onClick={() => {
                    storage.set("ORG_NAME", text);
                    this.$router.push({
                      path: `/organization/${row.id}/top-list-manage`,
                    });
                  }}
                >
                  {text}
                </a>
              );
            },
          },
        ],
      },
      pagination: { pageSize: 10, total: 0, current: 1 },
    };
  },
  created() {
    if (
      this.hasAction("add-or-delete-organization") &&
      this.table.columns.length < 2
    ) {
      this.table.columns.push({
        title: "管理",
        dataIndex: "columns-manage",
        scopedSlots: {
          customRender: "dataManage",
        },
      });
    }
  },
  methods: {
    onSearch() {
      this.pagination.current = 1;
      this.orgSource = [];
      this.fetchData();
    },
    onTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetchData();
    },
    async fetchData() {
      const keyword = this.params.inputStr;
      if (!this.validate()) return;
      this.table.loading = true;
      searchOrg({
        keyword,
        page: this.pagination.current - 1,
      })
        .then((res) => {
          let data = res.data.content;
          data.forEach((item, index) => {
            data[index].delShow = false;
          });
          this.params.organizationList = data;
          this.pagination.total = res.data.totalElements;
        })
        .finally(() => {
          setTimeout(() => {
            this.table.loading = false;
          }, 500);
        });
    },
    handleEdit(record = { id: -1 }) {
      this.$dialog(
        OrgForm,
        {
          record,
          on: {
            ok: () => {
              if (this.params.inputStr) this.fetchData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增机构",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    onDelClick(record) {
      checkOrgEnableDel({ orgId: record.id }).then((res) => {
        if (res.data === true) {
          record.delShow = true;
        } else if (res.data === false) {
          record.delShow = false;
          this.$notification["error"]({
            message: "该投资机构尚有投资记录，请删除投资记录后再删除该机构",
          });
        }
      });
    },
    confirmDelete(record) {
      record.delShow = false;
      deleteOrg({ id: record.id }).then((res) => {
        if (res.code === 0) {
          this.$message.success("删除成功");
          if (this.params.inputStr) this.fetchData();
        }
      });
    },
    validate() {
      if (!this.params.inputStr) {
        this.$message.warning("请输入机构名称！");
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="less" scoped>
.organization-list {
  background-color: white;
  padding: 20px;
  .action-area {
    display: flex;
    justify-content: space-between;
    .input-bar {
      width: 350px;
      margin-bottom: 10px;
    }
  }
}
</style>
