var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"tip":"加载中...","spinning":_vm.isSpinning}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"机构名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            initialValue: _vm.record.name,
            rules: [{ required: true, message: '请输入机构名称' }],
          } ]),expression:"[\n          'name',\n          {\n            initialValue: record.name,\n            rules: [{ required: true, message: '请输入机构名称' }],\n          },\n        ]"}],attrs:{"placeholder":"请输入机构名称"}})],1),_c('a-form-item',{attrs:{"label":"机构性质","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'type',
          {
            initialValue: _vm.record.type || 'INSTITUTION',
            rules: [{ required: true }],
          } ]),expression:"[\n          'type',\n          {\n            initialValue: record.type || 'INSTITUTION',\n            rules: [{ required: true }],\n          },\n        ]"}],staticStyle:{"width":"160px"},attrs:{"placeholder":"请选择机构性质"}},_vm._l((_vm.typeList),function(i){return _c('a-select-option',{key:i.type,attrs:{"value":i.type}},[_vm._v(" "+_vm._s(i.text)+" ")])}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }